import { datadogLogs } from "@datadog/browser-logs";

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event, status, message }) {
  const errorId = crypto.randomUUID();

  datadogLogs.logger.error(message, { error, errorId, event, status });

  return {
    url: event.url.pathname,
    message,
    errorId
  };
}
